import React from 'react';
import { graphql } from 'gatsby';

import PageHero from '../components/page-hero';
import Container from '../components/container';
import Column from '../components/column';
import Cta from '../components/cta';
import Heading from '../components/heading';
import Layout from '../components/layout';
import Row from '../components/row';
import { Section } from '../components/section';
import { Iframe, IframeContainer } from '../components/iframe';

const Dance = ({ data: { heroImg } }) => (
  <Layout>
    <PageHero img={heroImg} videoId="435816182">
      <Heading as="h1">Dance</Heading>
      <Cta to="#show-more">show more</Cta>
    </PageHero>
    <Section id="show-more">
      <Container>
        <Row>
          <Column offset="2">
            <Heading as="h2">Dance at Polish Boogie Festival</Heading>
            <p>
              How to characterize the music on Polish Boogie Festival? Definitely: hard-to-sit music. Boogie-woogie, blues or rockabilly are what you’ll hear in Człuchów during late July nights. No wonder that dance is an inseparable part of our festival.
            </p>
          </Column>
        </Row>
        <Row>
          <Column offset="2">
            <Heading as="h2">Dancing hosts</Heading>
            <p>
              We are lucky and have a great pleasure to cooperate with the best dance club in Poland: Swingstep.pl.
              All of the performances that can be seen during Polish Boogie Festival’s events are unique choreographies created by master boogie-woogie couples, annually invited to the festival from Poland and abroad.
            </p>
            <p>
              Playing with music and dance, the dancers repeatedly treat us with delicious improvisations, which are the core of the culture of the lindy hop, boogie-woogie or rock'n'roll style and also undoubtedly the heart of Polish Boogie Nights.
            </p>
          </Column>
        </Row>
        <Row justify="center">
          <IframeContainer>
            <Iframe title="vimeo-player" src="https://player.vimeo.com/video/435816182" frameBorder="0" allowFullScreen />
          </IframeContainer>
        </Row>
      </Container>
    </Section>
  </Layout>
);

export default Dance;

export const TaniecQuery = graphql`
  query TaniecQuery {
    heroImg: file(relativePath: { eq: "taniec.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
  }
`;
